import * as parser from "@babel/parser";

const parseJSX = (inputJSX) => {
  // Parse the JSX code into an AST (Abstract Syntax Tree)
  let ast = {};
  try {
    ast = parser.parse(inputJSX, {
      sourceType: "module",
      plugins: ["jsx"],
    });
  } catch (err) {
    ast = parser.parse(`<div>${inputJSX}</div>`, {
      sourceType: "module",
      plugins: ["jsx"],
    });
  }

  // Traverse the AST to build a more straightforward tree structure
  const parsedTree = traverseAST(ast);

  return parsedTree;
};

const traverseAST = (ast) => {
  const extractProps = (attributes) => {
    const props = {};
    attributes.forEach((attribute) => {
      if (attribute.type === "JSXAttribute") {
        let propName = attribute.name.name;
        let propValue = null;

        if (attribute.value === null) {
          // If attribute value is null, it's a shorthand for true
          propValue = true;
        } else if (
          attribute.value.type === "StringLiteral" &&
          propName !== "style"
        ) {
          propValue = attribute.value.value;
        } else if (attribute.value.type === "JSXExpressionContainer") {
          if (propName === "style") {
            propValue = attribute.value.expression.properties.reduce(
              (styleObj, prop) => {
                const key = prop.key.name;
                const value = prop.value.value || prop.value.expression;
                styleObj[key] = value;
                return styleObj;
              },
              {}
            );
          }
          // Handle other types of JSX expressions if needed
        }
        props[propName] = propValue;
      }
    });
    return props;
  };

  const extractElement = (node) => {
    if (node.type === "JSXFragment") {
      return {
        compName: "Fragment",
        compProps: {},
        children: node.children
          .map((child) => extractElement(child))
          .filter(Boolean),
      };
    } else if (node.type === "JSXElement") {
      const props = extractProps(node.openingElement.attributes);
      const children = node.children
        .map((child) => extractElement(child))
        .filter(Boolean);

      return {
        compName: node.openingElement.name.name,
        compProps: {
          ...props,
          className: undefined,
          class: undefined,
          style: undefined,
        },
        className: props.className
          ? props.className
          : props.class
          ? props.class
          : "",
        style: props.style ? props.style : {},
        children: children,
      };
    } else if (node.type === "JSXText") {
      // Capture the text content, trimming any extra whitespace
      const text = node.value.trim();
      // Only return non-empty text
      return text ? text : "";
    }
    return null;
  };

  return extractElement(ast.program.body[0].expression);
};

const getJSXAndScript = (parsedObject) => {
  console.log("getJSXAndScriptparsedObject", parsedObject);

  const convertProps = (props, className, style) => {
    console.log(props, style, className);
    let propsString = "";
    if (className) {
      propsString += `className="${className}" `;
    }
    if (style && Object.keys(style).length > 0) {
      const styleString = JSON.stringify(style)
        .replace(/"([^(")"]+)":/g, "$1:") // remove quotes around keys
        .replace(/"/g, "'"); // replace double quotes with single quotes
      propsString += `style={${styleString}} `;
    }

    if (props && Object.keys(props).length > 0) {
      propsString =
        propsString +
        Object.keys(props)
          .filter((key) => props[key] !== null && props[key] !== undefined)
          .map((key) => `${key}="${props[key]}"`)
          .join(" ");
    }

    console.log("propsStrpropsStringing", propsString);

    return propsString;
  };

  function convertChildren(children) {
    return children
      .map((child) => {
        if (typeof child === "string" || typeof child === "number") {
          return { jsx: child.toString(), script: "", bodyStyle: "" }; // Convert to string and ensure script is defined
        }
        return getJSXAndScript(child); // Nested component
      })
      .reduce(
        (prev, parsedChild) => {
          prev.jsx += parsedChild.jsx; // Concatenate jsx
          prev.script += parsedChild.script; // Concatenate script
          prev.bodyStyle += parsedChild.bodyStyle; // Concatenate bodyStyle
          return prev; // Return the accumulated object
        },
        { jsx: "", script: "", bodyStyle: "" }
      ); // Initial value with jsx and script properties
  }

  const { compName, compProps, className, style, script, bodyStyle, children } =
    parsedObject;

  console.log("compPropscompProps", compProps);
  console.log("stylestyle", style);
  console.log("classNameclassName", className);

  const propsString = convertProps(compProps, className, style);
  const childrenString = convertChildren(children);

  const combinedScript = `${script ? script : ""} ${
    childrenString.script ? childrenString.script : ""
  }`.trim();
  const combinedStyle = `${bodyStyle ? bodyStyle : ""} ${
    childrenString.bodyStyle ? childrenString.bodyStyle : ""
  }`.trim();

  return {
    jsx: `<${compName} ${propsString.trim()}>${
      childrenString.jsx
    }</${compName}>`,
    script: combinedScript,
    bodyStyle: combinedStyle,
  };
};

export { parseJSX, getJSXAndScript };
